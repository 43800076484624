import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _createSvgIcon2 from "../../createSvgIcon";
import * as _Remind3 from "@rsuite/icon-font/lib/legacy/Remind";

var _Remind2 = "default" in _Remind3 ? _Remind3.default : _Remind3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(_createSvgIcon2);

var _Remind = _interopRequireDefault(_Remind2); // Generated by script, don't edit it please.


var Remind = (0, _createSvgIcon["default"])({
  as: _Remind["default"],
  ariaLabel: "remind",
  category: "legacy",
  displayName: "Remind"
});
var _default = Remind;
exports["default"] = _default;
exports = exports.default;
export default exports;