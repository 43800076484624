import _Remind2 from "../lib/icons/legacy/Remind";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _Remind["default"];
  }
});

var _Remind = _interopRequireDefault(_Remind2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

export default exports;
export const __esModule = exports.__esModule;